// Turbolinks shims
import {setup as setupTurbolinksFacebookSDK} from '../src/shims/turbolinks-facebook-sdk';
import {setup as setupTurbolinksReload} from '../src/shims/turbolinks-reload';

// Component manager
import {initialize as initializeComponents} from '../src/lobby/lobby-component-manager';

// Make sure that the tracker doesn’t start before
// configuring it when the DOM is ready.
if (window.ahoy) window.ahoy.configure({startOnReady: false});

setupTurbolinksFacebookSDK();
setupTurbolinksReload();

initializeComponents();

window.console.log('Lobby v1.9.8');
