const facebookSDKURL = '//connect.facebook.net/fr_CA/all.js#xfbml=1';
const appID = window.Elo ? window.Elo.FacebookAppID : null;

window.fbAsyncInit = () => {
  window.FB.init({status: true, cookie: true, xfbml: true, appId: appID});

  // NOTE: We need to trigger this event to allow our
  //       components to bind correctly with the Facebook SDK.
  jQuery(document).trigger('FacebookSDKLoaded');
};

const bindFacebookEvents = () => {
  let facebookRoot;
  const $document = $(document);

  $document.on('turbolinks:request-start', () => {
    facebookRoot = $('#fb-root').detach();
  });

  $document.on('turbolinks:load', () => {
    const root = $('#fb-root');

    if (root.length > 0) {
      root.replaceWith(facebookRoot);
      return;
    }

    $('body').append(facebookRoot);
  });

  $document.on('turbolinks:load', () => {
    if (window.FB) window.FB.XFBML.parse();
  });
};

export const setup = () => {
  if (window.FB) return;

  $.getScript(facebookSDKURL)
    .done(() => bindFacebookEvents());
};
