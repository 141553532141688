export const setup = () => {
  window.Turbolinks.reload = (opts) => {
    opts = {keepScrollPosition: false, ...opts};

    if (opts.keepScrollPosition) {
      // Keep current scroll position
      window.Turbolinks.originalOffset = $('body').scrollTop();

      // Bind event that will be triggered after the page is reloaded
      $(document).on('turbolinks:load.TurbolinksReload', () => {
        $('body').scrollTop(window.Turbolinks.originalOffset);

        $(document).off('turbolinks:load.TurbolinksReload');
      });
    }

    // Visit the same URL
    window.Turbolinks.visit(window.location.pathname + window.location.search);
  };
};
